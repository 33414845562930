import React, { useState, useEffect } from 'react'
import './Skills.scss'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import { client, urlFor } from '../../client'
// import { sass, html, redux, nodejs, python, reactjs, nextjs, js, pwa, css, git, figma, } from "../../constants"
import {frameworksNskills} from "./../../Data/frameworksNskills.js"

// import Tippy from '@tippy.js/react'
// import './tippy.js/dist/tippy.css'

const Skills = () => {
  const [skillsData, setskillsData] = useState(frameworksNskills)

  return (
    <>
      <h2 className="head-text"><span>S</span>kills</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skillsData.map((skill, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={index}
            >
              <div
                className="app__flex skillsBG"
              >
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  )

}

export default AppWrapp(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'skill-section',
)
