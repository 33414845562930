

export const workexperience =[
    {
        id:1,
        company:"Xeraxlabs Ltd",
        roles:[
            {
                title:"Software Developer",
                stillActive: true,
                type:"remote",
                startDate:{Month:"Dec",Year:"2022"},
                endDate:{Month:"",Year:""},
                location:{state:"Ondo",city:"Akure",countryCode:"NG"}
        }
    ],
        
    }
    ,{
        id:2,
        company:"Techclub Ng",
        roles:[
            {
                title:"Software Programming Instructor",
                stillActive: false,
                type:"remote",
                startDate:{Month:"Feb",Year:"2021"},
                endDate:{Month:"Dec",Year:"2022"},
                location:{state:"Ondo",city:"Akure",countryCode:"NG"}
        }
            ,{
                title:"Frontend Developer",
                stillActive: false,
                type:"remote",
                startDate:{Month:"Feb",Year:"2020"},
                endDate:{Month:"Feb",Year:"2021"},
                location:{state:"Ondo",city:"Akure",countryCode:"NG"}
        }
    ],
        
    }
    ,{
        id:3,
        company:"Project Arata",
        roles:[
            {
                title:"Frontend Developer",
                stillActive: false,
                type:"remote",
                startDate:{Month:"Sep",Year:"2021"},
                endDate:{Month:"Oct",Year:"2022"},
                location:{state:"Ondo",city:"Akure",countryCode:"NG"}
        }
    ],
        
    }
    ,{
        id:4,
        company:"De Whales Organization",
        roles:[
            {
                title:"IT Support",
                stillActive: false,
                type:"remote",
                startDate:{Month:"Jan",Year:"2018"},
                endDate:{Month:"Jan",Year:"2020"},
                location:{state:"Ondo",city:"Akure",countryCode:"NG"}
        }
    ],
        
    }
    ,{
        id:5,
        company:"I-Tec247 Ltd",
        roles:[
            {
                title:"IT Support",
                stillActive: false,
                type:"On site",
                startDate:{Month:"Jul",Year:"2016"},
                endDate:{Month:"Dec",Year:"2017"},
                location:{state:"Plateau",city:"Jos",countryCode:"NG"}
        }
    ],
        
    }

]