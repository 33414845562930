import {sass,html5,redux,nodejs,python,javascript,reactjs,nextjs,pwa,css,tailwindcss,git,docker,scss,typescript,reactnative} from "./../constants/images.js"

export const frameworksNskills=[
    {
        id:1,
        bgColor:"#008BF5",
        icon:sass,
        name: "Sass"
    }
    ,{
        id:2,
        bgColor:"#008BF5",
        icon:html5,
        name: "Html 5"
    }
    ,{
        id:3,
        bgColor:"#008BF5",
        icon:redux,
        name: "Redux"
    }
    ,{
        id:4,
        bgColor:"#008BF5",
        icon:nodejs,
        name: "Node Js"
    }
    ,{
        id:5,
        bgColor:"#008BF5",
        icon:python,
        name: "Python"
    }
     ,{
        id:6,
        bgColor:"#008BF5",
        icon:javascript,
        name: "Javascript"
    },{
        id:7,
        bgColor:"#008BF5",
        icon:reactjs,
        name: "React Js"
    } 
    ,{
        id:8,
        bgColor:"#008BF5",
        icon:nextjs,
        name: "Next Js"
    }
    ,{
        id:9,
        bgColor:"#008BF5",
        icon:pwa,
        name: "Pwa"
    }
  
    ,{
        id:10,
        bgColor:"#008BF5",
        icon:css,
        name: "CSS"
    }
    ,{
        id:11,
        bgColor:"#008BF5",
        icon:tailwindcss,
        name: "Taiwind Css"
    }
    ,{
        id:12,
        bgColor:"#008BF5",
        icon:git,
        name: "Git"
    }
    ,{
        id:13,
        bgColor:"#008BF5",
        icon:docker,
        name: "Docker"
    }
    ,{
        id:14,
        bgColor:"#008BF5",
        icon:scss,
        name: "Scss"
    }
    ,{
        id:14,
        bgColor:"#008BF5",
        icon:typescript,
        name: "Typescript"
    }
    ,{
        id:15,
        bgColor:"#008BF5",
        icon:reactnative,
        name: "React Native"
    }
]