import {citratechPrev,veggieshutPrev,metaversaPrev,nutrisnowPrev,foodplugPrev,amillPrev,nodefarmPrev,unrealPrev} from "./../constants/images.js"

export const workportfolio = [
    {
        id:1,
        title:"Citratech Solar",
        description:"A solar retail company E-commerce App",
        image:citratechPrev,
        tags:['REACT JS', 'ALL'],
        technologies:['REACT JS', 'CSS', 'JSX'],
        previewLink:"https://citratech.netlify.app/"

}
,
    {
        id:2,
        title:"VeggiesHut",
        description:"A vegetarian E-commerce App.",
        image:veggieshutPrev,
        tags:['REACT JS', 'ALL'],
        technologies:['REACT JS', 'CSS', 'JSX'],
        previewLink:"https://veggieshut.netlify.app/",
        githubLink:"https://github.com/akinwumidi/veggieshut.git"

}
,
    {
        id:3,
        title:"Metaversa",
        description:"A simple metaverse landing page",
        image:metaversaPrev,
        tags:['NEXT JS', 'ALL'],
        technologies:['NEXT JS', 'CSS', 'JSX'],
        previewLink:"https://metaversa.netlify.app/",
        githubLink:"https://github.com/akinwumidi/metaversa.git"

}
,
    {
        id:4,
        title:"Nutrisnow",
        description:"A simple farmers market landing page",
        image:nutrisnowPrev,
        tags:['HTML & CSS', 'ALL'],
        technologies:['HTML', 'CSS', 'JAVASCRIPT', 'SWIPER JS', 'BOOTSTRAP 5'],
        previewLink:"https://nutrisnow.netlify.app/",
        githubLink:"https://github.com/akinwumidi/nutrisnow.git"

}
,
    {
        id:5,
        title:"Food plug ",
        description:"Foodplug Restaurant",
        image:foodplugPrev,
        tags:['HTML & CSS', 'ALL'],
        technologies:['HTML', 'CSS', 'JAVASCRIPT'],
        previewLink:"https://foodplug.netlify.app",
        githubLink:"https://github.com/akinwumidi/FoodPlug"

}
,
    {
        id:6,
        title:"AMILL ",
        description:"Simple Modern landing page with smooth animation",
        image:amillPrev,
        tags:['REACT JS', 'ALL'],
        technologies:['REACT JS', 'CSS', 'JSX'],
        previewLink:"https://react-amill.netlify.app",
        githubLink:"https://github.com/akinwumidi/React-Amill"

}
,
    {
        id:7,
        title:"Node products",
        description:"Fresh farm products showcase",
        image:nodefarmPrev,
        tags:['NODE JS', 'ALL'],
        technologies:['CSS','NODE JS'],
        previewLink:"https://node-farm-3syl.onrender.com/",
        githubLink:"https://github.com/akinwumidi/Node-FarmProducts.git"

}
,
    {
        id:7,
        title:"Unreal",
        description:"A simple Game themed website",
        image:unrealPrev,
        tags:['HTML & CSS', 'ALL'],
        technologies:['HTML', 'CSS', 'JAVASCRIPT'],
        previewLink:"https://unreall.netlify.app/",
        githubLink:"https://github.com/akinwumidi/unreal.git"

}

]