import React, { useState, useEffect } from 'react'
import './services.scss'
import { motion } from 'framer-motion'
import { penImg, codeImg } from '../../constants/images'
import { client, urlFor } from '../../client'
import { AppWrapp, MotionWrap } from '../../wrapper'

const Services = () => {
  // sanity fecth services
  // const [aboutsData, setAboutsData] = useState([])
  // useEffect(() => {
  //   const query = '*[_type == "services"]'

  //   client.fetch(query).then((data) => setAboutsData(data))
  // }, [])

  return (
    <>
      <h2 className="head-text"><span>S </span>ervices</h2>
      <div className="app__about">
        <div className="app__profiles">
          <div className="services-intro">
            Let's talk <br />
            about your project.
            <a href="#contact">
              <button className="header__btn"> connect</button>
            </a>
          </div>
          {/*
          aboutsData.map((service, index) => (
            <motion.div
              key={`index-${service.title}`}
              whileInView={{ opacity: [0, 1] }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: 'tween' }}
              className="app__profile-item"
            >
              <div className="serviceholder-overlay"></div>
              <div className="services-Imgholder">
                <img src={urlFor(service.imgUrl)} alt={service.title} />
              </div>
              <div className="services-details">
                <h2 className="bold-text" style={{ marginTop: 8 }}>
                  {service.title}
                </h2>
                <p className="p-text" style={{ marginTop: 6 }}>
                  {service.description}
                </p>
              </div>
            </motion.div>
          ))
          */}

          <motion.div
            whileInView={{ opacity: [0, 1] }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
          >
            <div className="serviceholder-overlay"></div>
            <div className="services-Imgholder">
              <img src={codeImg} alt="Web design logo" />
            </div>
            <div className="services-details">
              <h2 className="bold-text" style={{ marginTop: 8 }}>
                Web Development
              </h2>
              <p className="p-text" style={{ marginTop: 6 }}>
                I craft visually appealing, responsive, and 
optimized web applications from Photoshop (PSD), Figma, Adobe Illustrator(AI) and Png designs that 
align pixel-perfectly with given specifications.
                
              </p>
              {/* <p>
                with ample years of experience in areas of design, I develop and deliver beautiful and highly scalable web applications that functions well across multiple device.
                </p> */}
            </div>
          </motion.div>

          <motion.div
            whileInView={{ opacity: [0, 1] }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
          >
            <div className="serviceholder-overlay"></div>
            <div className="services-Imgholder">
              <img src={penImg} alt="Web design logo" />
            </div>
            <div className="services-details">
              <h2 className="bold-text" style={{ marginTop: 8 }}>
                Mobile App Development
              </h2>
              <p className="p-text" style={{ marginTop: 6 }}>
                I specialize in creating dynamic, user-centric mobile applications optimized for speed, security, and scalability that offer seamless experiences across all devices (iOS and Android).
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  )
}

export default AppWrapp(
  MotionWrap(Services, 'app__services'),
  'services',
  'services-section',
)
