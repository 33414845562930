import './NavigationDots.scss'
import React from 'react'

const activedotColor = {
  background: '#215FF6',
}

const NavigationDots = ({ IDName }) => {
  return (
    <div className="app__navigation">
      {['home', 'services', 'work', 'skills','experience', 'contact'].map((link, index) => (
        <a
          href={`#${link}`}
          key={`${index} - ${link}`}
          style={IDName === link ? activedotColor : {}}
          className="app__navigation-dot"
        >
        </a>
      ))}
    </div>
  )
}

export default NavigationDots
