import React, { useState } from 'react'
import './Workexperience.scss'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { motion } from 'framer-motion'
import {workexperience} from "./../../Data/workexperience.js"

// import Tippy from '@tippy.js/react'
// import './tippy.js/dist/tippy.css'

const Experience = () => {
  const [experienceData, setexperienceData] = useState(workexperience);
// console.log(experienceData);
  return (
    <>
      <h2 className="head-text">
        <span>W</span>ork Experience
      </h2>

      <div className="app__exp-container">
        <motion.div className="app__skills-exp">
          {experienceData?.map((job, index) => (
            <motion.div className="app__skills-exp-item" key={`year-${index}`}>
              <div className="app__skill-exp-year date-timeline-container">
                {job.roles[0].stillActive ? (
                  <span>Present</span>
                ) : (
                  <>
                    <span className="date-timeline-start">
                      {job.roles[job.roles.length - 1].startDate.Year}
                    </span>
                    <span className="date-timeline-line"></span>
                    <span className="date-timeline-end">
                      {job.roles[0].endDate.Year}
                    </span>
                  </>
                )}
              </div>
              <motion.div className="app__skills-exp-works">
                <h3 className="bold-text">{job.company}</h3>
                {experienceData[index].roles.map((role, index) => (
                  <motion.div
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={role.title}
                    key={`company-${index}`}
                  >
                    <p>
                      {" "}
                      {role.startDate.Month} {role.startDate.Year} -{" "}
                      {role.stillActive
                        ? "Present"
                        : `${role.endDate.Month} ${role.endDate.Year}`}
                    </p>
                    <p className="bold-text p-text">{role.title}</p>
                    {/* <p className="p-text">{work.company}</p> */}
                  </motion.div>
                ))}
                <div className="skillball"></div>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );

}

export default AppWrapp(
  MotionWrap(Experience, 'app__skills'),
  'experience',
  'experience-section',
)
