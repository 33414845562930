import React, { useState, useRef } from "react";
import "./Contact.scss";
import { AppWrapp, MotionWrap } from "../../wrapper";
// import { client } from "../../client";
// import { FaLinkedinIn } from "react-icons/fa";
// import { SiGmail } from "react-icons/si";
// import { CgPhone } from "react-icons/cg";
// import { AiOutlineSmile } from "react-icons/ai";
import { Images } from "../../constants";
// npm install emailjs-com
import emailjs from "emailjs-com";

const Contact = () => {
  // const form = useRef();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [error, setError] = useState("");
  const [toastVisible, setToastVisible] = useState(false);

  const showToast = (toastMessage, isError = false) => {  
    setToastMessage(toastMessage);
    setError(isError ? toastMessage : "");
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000);
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
    from_name: "Akinwumidi.dev",
    reply_to: "aki21001@byui.edu"
  });
  
  // const { name, email, message } = formData;
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    setSendingMessage(true);
     setTimeout(() => {
       setSendingMessage(false);
     }, 1700);
    
    const serviceID = "service_xvmapso";
    const templateID = "template_tbstmqt";
    const userID = "s551poN9iKkxsPLE4";

    // emailjs.sendForm(serviceID, templateID, form.current, userID).then(
    emailjs.send(serviceID, templateID, formData, userID).then(
      (result) => {
        setLoading(false);
        showToast("Message Sent succesfully", false);
        setFormData({
          user_name: "",
          user_email: "",
          message: "",
          from_name: "Akinwumidi.dev",
          reply_to: "aki21001@byui.edu",
        });
      },
      (error) => {
        console.log(error.text);
        setLoading(false);
        showToast("Error ! Please try again", true); 
      }
    );
  };


  return (
    <>
      {sendingMessage && (
        <div className="toast messageToast">
          Sending message
          <div className="toast-progress"></div>
        </div>
      )}
      {toastVisible && (
        <div
          className={`toast statusToast ${
            error ? "error-toast" : "success-toast"
          }`}
        >
          {toastMessage}
          <div className="toast-progress"></div>
        </div>
      )}
      <div className="app__center-trick">
        <h2 className="head-text">
          <span> c</span>onnect !!
        </h2>
      </div>
      {/* <p>
        Hi there, I am super excited to talk to you about your next big app, let
        me turn your amazing ideas to code and deliver highly responsive and eye
        catching design that suits just what you want them for.
      </p> */}
      <div className="contact-container">
        <>
          <form
            // ref={form}
            onSubmit={sendEmail}
            className="app__flex app__contact-form "
          >
            <h3 className="title">Leave a message</h3>
            <input
              type="text"
              name="user_name"
              className="form-input"
              placeholder="Enter your name"
              value={formData.user_name}
              onChange={handleChangeInput}
              required
            />
            <input
              type="text"
              name="user_email"
              className="form-input"
              placeholder="Enter your email"
              value={formData.user_email}
              onChange={handleChangeInput}
              required
            />
            <textarea
              name="message"
              value={formData.message}
              placeholder="Your message"
              onChange={handleChangeInput}
              className="form-input"
              required
            />
            <button className="form-btn" type="submit" value="Send">
              {loading ? "Sending ..." : "Send Message"}
            </button>
          </form>
        </>

        <div className="app__contact-card">
          {/* <div className="contact__card-info">
        <h3 className="title">Fast lane</h3>

            <a
              href="http://www.linkedin.com/in/akinwumidi"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <FaLinkedinIn />
              </span>
              <span className="fastlane-text" >linkedin.com/in/akinwumidi</span>
            </a>

            <a
              href="tel:+2348158316730"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <CgPhone />
              </span>
              <span className="fastlane-text" >+234 (815) 831-6730</span>
            </a>

            <a
              href="mailto:israelakin14@gmail.com"
              className="contact__card-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="contact__card-iconHolder">
                <SiGmail />
              </span>
              <span className="fastlane-text" >israelakin14@gmail.com</span>
            </a>

          </div>*/}
          <div className="app_header-overlay">
            <img
              src={Images.ibgroup}
              className="bgshapes ibgroup"
              alt="overlay-particles"
            />
            <img
              src={Images.isound}
              className="bgshapes isound"
              alt="overlay-particles"
            />
            <img
              src={Images.iboxball}
              className="bgshapes iboxball"
              alt="overlay-particles"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrapp(
  MotionWrap(Contact, "contact"),
  "contact",
  "contact-section"
);
