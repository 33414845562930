import React, { useState, useEffect } from 'react'
import './portfolio.scss'
import { motion } from 'framer-motion'
import { AppWrapp, MotionWrap } from '../../wrapper'
import { client, urlFor } from '../../client'
import { FiGithub } from 'react-icons/fi'
import { HiOutlineExternalLink } from 'react-icons/hi'
import {workportfolio} from "./../../Data/workportfolio.js"



const Portfolio = () => {
  const [activeFilter, setActiveFilter] = useState('ALL')
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
  const [Works, setWorks] = useState(workportfolio)
  const [FilterWork, setFilterWork] = useState(workportfolio)

  const handleWorkFilter = (item) => {
    setActiveFilter(item)
    setAnimateCard([{ y: 100, opacity: 0 }])

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }])
    }, 500)

    if (item === 'ALL') {
      setFilterWork(Works)
    } else {
      setFilterWork(Works.filter((work) => work.tags.includes(item)))
    }
  }

  // sanity query fetch
  // useEffect(() => {
  //   const query = '*[_type=="works"]'
  //   client.fetch(query).then((data) => {
  //     console.log(data)
  //     setWorks(data)
  //     setFilterWork(data)
  //   })
  // }, [])

  // useEffect(() => { 
  //     setWorks(workportfolio)
  //     setFilterWork(workportfolio)
  // }, [])

  const NoPortfolio = () => (
    <div className="no-portfolio">
      <h3> Portfolio not synchronized yet 😎</h3><button onClick={() => handleWorkFilter("ALL")}>Show All</button>
    </div>)


  const PortfolioContent = () =>
    FilterWork.map((work, index) => (
      <div className="app__work-wrapper" key={index}>

        <div className="app__work-title">
          <a href={work.previewLink} target="_blank" rel="noreferrer">
            <span className="">{work.title}</span>
          </a>
        </div>

        <div className="app__work-item app__flex">
          <div className="app__work-img app__flex">
            <img src={work.image} alt={work.title} />
            <a href={work.previewLink} target="_blank" className="app__work-descontainer">
              <div className="app__work-desc">
                <p className="p-text" style={{ marginTop: 10 }}>
                  {work.description}
                </p>
              </div>

              <div className="app__work-tech app__flex">
                {work.technologies.map((tech, index) => (
                  <span key={index}>{tech}</span>
                ))}
              </div>
            </a>
          </div>
          <div className="app__work-content app__flex">
            <div className="app__work-actions">


              <div className="app__work-tag app__flex">
                <p>{work.title}</p>
              </div>

              <a href={work.previewLink} target="_blank" rel="noreferrer">
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  whileHover={{ scale: [1, 0.9] }}
                  transition={{ duration: 0.25 }}
                  className="app__flex"
                >
                  <HiOutlineExternalLink />
                </motion.div>
              </a>
             {work.githubLink && (<a href={work.githubLink} target="_blank" rel="noreferrer">
                <motion.div
                  whileInView={{ scale: [0, 1] }}
                  whileHover={{ scale: [1, 0.9] }}
                  transition={{ duration: 0.25 }}
                  className="app__flex"
                >
                  <FiGithub />
                </motion.div>
              </a>)}

            </div>
          </div>

        </div>
      </div>
    ))


  return (
    <>
      <h2 className="head-text"><span>p</span>ortfolio</h2>
      <div className="app__work-filter">
        {/* {['UI/UX', 'HTML & CSS', 'NODE JS', 'NEXT JS', 'REACT JS', 'ALL'].map( */}
        {['ALL','HTML & CSS', 'NODE JS', 'REACT JS'].map(
          (item, index) => (
            <div
              href="#work"
              key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''
                }`}
            >
              {item}
            </div>
          ),
        )}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {FilterWork.length === 0 ? <NoPortfolio /> : <PortfolioContent />}
      </motion.div>
    </>
  )

}

export default AppWrapp(
  MotionWrap(Portfolio, 'app__works'),
  'work',
  'portfolio-section',
)
