


// import profile from '../assets/blob.png';
import profile from '../assets/intro_img.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';


import icircle from '../assets/icircle.png';
import ihcircle from '../assets/ihcircle.png';
import iwibble from '../assets/iwible.png';
import itriangle from '../assets/itriangle.png';
import idotted from '../assets/idotted.png';
import isound from '../assets/isound.png';
import iamoeba from '../assets/iamoeba.png';
import ibox from '../assets/ibox.png';
import iboxball from '../assets/iboxball.png';
import ibgroup from '../assets/ibGroup.png';
import ihedge from '../assets/ihedge.png';
import isnake from '../assets/isnake.png';
import resume from '../assets/israel_akinwumi_resume.pdf';



export default {
    profile,
    circle,
    logo,
    icircle,
    ihcircle,
    iwibble,
    itriangle,
    idotted,
    isound,
    iamoeba,
    ibox,
    iboxball,
    ibgroup,
    ihedge,
    isnake,
    resume,
};

// Work portfolio
export {default as citratechPrev} from '../assets/citratechPrev.png';
export {default as veggieshutPrev} from '../assets/veggieshutPrev.png';
export {default as metaversaPrev} from '../assets/metaversaPrev.png';
export {default as nutrisnowPrev} from '../assets/nutrisnowPrev.png';
export {default as foodplugPrev} from '../assets/foodplugPrev.png';
export {default as amillPrev} from '../assets/amillPrev.png';
export {default as nodefarmPrev} from '../assets/nodefarmPrev.png';
export {default as unrealPrev} from '../assets/unrealPrev.png';
export {default as skilledelearnPrev} from '../assets/skilledelearnPrev.png';

// Frameworks and skills
export {default as sass} from '../assets/sass-800x800.svg';
export {default as html5} from '../assets/html-800x800.svg';
export {default as redux} from '../assets/redux-800x800.svg';
export {default as nodejs} from '../assets/nodejs-800x800.svg';
export {default as python} from '../assets/python-800x800.svg';
export {default as reactjs} from '../assets/reactjs-800x800.svg';
export {default as nextjs} from '../assets/nextjs-800x800.svg';
export {default as javascript} from '../assets/js-800x800.svg';
export {default as pwa} from '../assets/pwa-800x800.svg';
export {default as css} from '../assets/css-800x800.svg';
export {default as git} from '../assets/git-800x800.svg';
export {default as tailwindcss} from '../assets/tailwindcss-svgrepo-com.svg';
export {default as docker} from '../assets/docker-svgrepo-com.svg';
export {default as scss} from '../assets/scss-svgrepo-com.svg';
export {default as typescript} from '../assets/typescript-icon-svgrepo-com.svg';
export {default as reactnative} from '../assets/react-16-svgrepo-com.svg';

// services
export {default as penImg} from '../assets/pen-800x800.svg';
export {default as codeImg} from '../assets/code-800x800.svg';