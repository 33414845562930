import React from 'react'
import './header.scss'
import { motion } from 'framer-motion'
import { Images } from '../../constants'
import { Sidecontroller } from './../../components'

import { AppWrapp } from '../../wrapper'

// const scaleVariants = {
//   whileInView: { scale: [0, 1], opacity: [0, 1] },
//   transition: { duration: 1, ease: 'easeInOut' },
// }

const Header = () => {
  return (
    <div className="app__header app__flex">
      <Sidecontroller />

      <div className="app_header-overlay">
        {/* <img src={Images.logo} className="bgshapes namedlogo" /> */}
        <img
          src={Images.ibgroup}
          className="bgshapes ibgroup"
          alt="overlay-particles"
        />
        {/* <img src={Images.ibox} className="bgshapes ibox" /> */}
        <img
          src={Images.ihcircle}
          className="bgshapes ihcircle"
          alt="overlay-particles"
        />
        {/* <img
          src={Images.iamoeba}
          className="bgshapes iamoeba"
          alt="overlay-particles"
        />*/}
        {/* <img src={Images.isnake} className="bgshapes isnake" /> */}
        {/* <img src={Images.ihedge} className="bgshapes ihedge" /> */}
        {/* <img src={Images.itriangle} className="bgshapes itriangle" /> 
        <img
          src={Images.isound}
          className="bgshapes isound"
          alt="overlay-particles"
        />
        {/* <img src={Images.iboxball} className="bgshapes iboxball" /> */}
        {/* <img src={Images.idotted} className="bgshapes idotted" /> */}
      </div>
      <div className="app_header-center">
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__header-info"
        >
          <div className="dev_username">
            <h1 className='dev_username-firstname'>
              <span> I</span>srael

            </h1>
            <h1 className='dev_username-lastname'> Akinwu <span> mi</span>
            </h1>
          </div>

          {/* <p>
            Hello, I'm Israel Damilola Akinwumi, A motivated Front-end Developer
            with keen eye for beautiful and responsive web applications. I have
            great love & passion for creating digital experiences that look and
            function beautifully across multiple device.
  </p> */}
          {/* <p>
            Hello 👋 , I'm a highly skilled Software Developer adept in bringing forth expertise in design, testing
            and maintenance of software systems. I'm experienced with the latest cutting edge development tools and procedures.
          </p> */}
          <p>
            Hi, I am a goal oriented and results driven software developer. I design, test and maintain software applications. I deliver high quality work on time , paying close attention to every detail, ensuring all specifications are met
          </p>
          <a href={Images.resume}>
            <button className="header__btn">Download Resume</button>
          </a>
        </motion.div>
        <motion.div
          whileInView={{ x: [100, 0] }}
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          className="app__header-img"
        >
          <motion.img
            whileInView={{ scale: [0.7, 1] }}
            transition={{ duration: 1, ease: 'easeIn' }}
            className="profile__blob"
            src={Images.profile}
            alt="profile_circle"
          />
        </motion.div>
      </div>

      {/* 

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[Images.flutter, Images.redux, Images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt={`circle_${circle}`} />
          </div>
        ))}
      </motion.div> */}
    </div>
  )
}

export default AppWrapp(Header, 'home', 'app__home')
